@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: 'Raleway', sans-serif;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html, body {
    overflow-x: hidden;
    background-color: #111;
}
.App {
    background-image: url(/static/media/bg.18db7c72.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height:100vh;
    width:100vw;
    overflow-x:hidden;
    color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x:hidden;
}
.mainContent {
    display: block;
    padding: 2rem;
}
.logoWrap {
    margin: 0 0 3rem 0;
    text-align: center;
}
.logoSvg {
    width:calc(100% - 40px);
    height:auto;
    max-width:calc(600px + 4rem);
}
.explainer {
    max-width:600px;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 2rem;
    border-top: 4px solid #FA6216FF;
    border-bottom: 4px solid #FA6216FF;
    background-color: rgba(25, 25, 25, 0.5);
}
.explainer .inner {
    font-size: 1rem;
    line-height: 1.5;
}
h2 {
    font-size: 2rem;
    margin: 0;
    text-align:center;
}

.twitter-wrap{
    text-align:center;
}

.twitter-icon{
    width:3rem;
    height:auto;
    padding:0;
    margin:0;
    position: relative;
    top: 10px;
    margin-right: .5rem;
    transition: 0.3s ease-out transform;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    display: inline-block;
}
.twitter-link{
    padding: 3rem 0;
    text-decoration:none;
    display:block;
    transition: 0.3s ease-out transform;
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
}
.twitter-link:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.twitter-link:hover .twitter-icon {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
}

.twitter-text{
    font-size:3rem;
    color: #eee;
    line-height: 1;
}

.anim-default{
    transition: 0.5s ease-out opacity, 0.9s ease-out transform;
    -webkit-transform: translateY(2rem);
            transform: translateY(2rem);
    opacity: 0;
}
.anim-default.anim-active{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
}
.anim-logo {
    transition: 1.5s ease-out opacity, 0.9s ease-out transform;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
}
.anim-logo.anim-active{
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
.anim-text{
    overflow-x: hidden;
    transition: 0.5s ease-out opacity, 0.9s ease-out transform;
    -webkit-transform: translateX(10rem);
            transform: translateX(10rem);
    opacity: 0;
}
.anim-text .inner {
    transition: 0.5s ease-out opacity, 0.9s ease-out transform;
    transition-delay: 0.75s;
    -webkit-transform: translateX(-20rem);
            transform: translateX(-20rem);
    opacity: 0;
}
.anim-text.anim-active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
}
.anim-text.anim-active .inner{
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
}

.anim-delay-1{
    transition-delay: 0.25s;
}
.anim-delay-2{
    transition-delay: 0.5s;
}
.anim-delay-3{
    transition-delay: 1.3s;
}
.anim-delay-4{
    transition-delay: 1.75s;
}

