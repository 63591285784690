html, body {
    overflow-x: hidden;
    background-color: #111;
}
.App {
    background-image: url('../images/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height:100vh;
    width:100vw;
    overflow-x:hidden;
    color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x:hidden;
}
.mainContent {
    display: block;
    padding: 2rem;
}
.logoWrap {
    margin: 0 0 3rem 0;
    text-align: center;
}
.logoSvg {
    width:calc(100% - 40px);
    height:auto;
    max-width:calc(600px + 4rem);
}
.explainer {
    max-width:600px;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 2rem;
    border-top: 4px solid #FA6216FF;
    border-bottom: 4px solid #FA6216FF;
    background-color: rgba(25, 25, 25, 0.5);
}
.explainer .inner {
    font-size: 1rem;
    line-height: 1.5;
}
h2 {
    font-size: 2rem;
    margin: 0;
    text-align:center;
}

.twitter-wrap{
    text-align:center;
}

.twitter-icon{
    width:3rem;
    height:auto;
    padding:0;
    margin:0;
    position: relative;
    top: 10px;
    margin-right: .5rem;
    transition: 0.3s ease-out transform;
    transform: rotate(0deg);
    display: inline-block;
}
.twitter-link{
    padding: 3rem 0;
    text-decoration:none;
    display:block;
    transition: 0.3s ease-out transform;
    transform: scale(1.0);
}
.twitter-link:hover {
    transform: scale(1.1);
}
.twitter-link:hover .twitter-icon {
    transform: rotate(15deg);
}

.twitter-text{
    font-size:3rem;
    color: #eee;
    line-height: 1;
}

.anim-default{
    transition: 0.5s ease-out opacity, 0.9s ease-out transform;
    transform: translateY(2rem);
    opacity: 0;
}
.anim-default.anim-active{
    transform: translateY(0);
    opacity: 1;
}
.anim-logo {
    transition: 1.5s ease-out opacity, 0.9s ease-out transform;
    transform: scale(1.5);
    opacity: 0;
}
.anim-logo.anim-active{
    transform: scale(1);
    opacity: 1;
}
.anim-text{
    overflow-x: hidden;
    transition: 0.5s ease-out opacity, 0.9s ease-out transform;
    transform: translateX(10rem);
    opacity: 0;
}
.anim-text .inner {
    transition: 0.5s ease-out opacity, 0.9s ease-out transform;
    transition-delay: 0.75s;
    transform: translateX(-20rem);
    opacity: 0;
}
.anim-text.anim-active {
    transform: translateX(0);
    opacity: 1;
}
.anim-text.anim-active .inner{
    transform: translateX(0);
    opacity: 1;
}

.anim-delay-1{
    transition-delay: 0.25s;
}
.anim-delay-2{
    transition-delay: 0.5s;
}
.anim-delay-3{
    transition-delay: 1.3s;
}
.anim-delay-4{
    transition-delay: 1.75s;
}
